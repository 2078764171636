html {
  padding: 20px 0;
}

html,
body {
  height: 100%;
}

body {
}

progress {
  width: 300px;
  margin: 20px 0 0;
}

#dropArea.highlight {
  border-color: #007aff;
}

#app {

  padding: 20px 30px;
  width: 950px;
  margin: 0 auto;
}
